import React from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Container,
  useTheme,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import FormTextField from "../components/form/FormTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../features/slices/alertSlice";
import { useTranslation } from "react-i18next";

import { useResetPasswordMutation } from "../features/api/publicApiSlice";

const ResetPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const resetPasswordSchema = yup.object().shape({
    newPassword: yup.string().required(t("validation.newpassword")),
    reEnterPassword: yup
      .string()
      .required(t("validation.reenterpassword"))
      .oneOf([yup.ref("newPassword"), null], t("validation.passwordnotmatch")),
  });

  const { control: resetPasswordControl, handleSubmit } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const [resetPassword, { data, isLoading, isError, isSuccess, error }] =
    useResetPasswordMutation();

  const submitPassword = async (data) => {
    let formData = {
      code: searchParams.get("code"),
      email: searchParams.get("email"),
      password: data?.newPassword,
      ts: searchParams.get("ts"),
    };
    await resetPassword(formData).unwrap();
    navigate("/");
  };

  if (!isLoading && isSuccess) {
    dispatch(
      setAlert({
        isOpen: true,
        message: t("resetpassword.successmessage"),
        variant: "success",
      })
    );
  }

  if (isError) {
    dispatch(
      setAlert({
        isOpen: true,
        message: error.data.message,
        variant: "error",
      })
    );
  }

  return (
    <>
      {mobileView && (
        <Grid container justify="center" py={1}>
          <Grid item xs={4}>
            <IconButton onClick={() => navigate("/")}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <Typography
              textAlign="center"
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              {t("resetpassword.title")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Container>
        {!mobileView && (
          <Typography
            fontWeight={800}
            fontSize={14}
            color={theme.palette.primary.main}
            mt={5}
            mb={2}
          >
            {t("resetpassword.title")}
          </Typography>
        )}
        <Box
          py={3}
          mt={mobileView && 5}
          px={mobileView ? 10 : 40}
          // mb={5}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <form onSubmit={handleSubmit(submitPassword)}>
            <FormTextField
              label={t("resetpassword.newpassword")}
              control={resetPasswordControl}
              controllerName="newPassword"
              defaultValue={""}
              type="password"
              size="small"
            />
            <FormTextField
              label={t("resetpassword.reenterpassword")}
              control={resetPasswordControl}
              controllerName="reEnterPassword"
              defaultValue={""}
              type="password"
              size="small"
            />
            <Box display="flex" justifyContent="center" mt="30px">
              <Button variant="contained" type="submit">
                {t("resetpassword.submit")}
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;
