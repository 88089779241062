import React, { useState } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const Image = ({
  src,
  onLoad,
  sx,
  height,
  maxHeight,
  width,
  onClick,
  hovered,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleUnhover = () => {
    setIsHovered(false);
  };

  const styles = {
    transform: isHovered ? "scale(1.2)" : "scale(1)",
    transition: "transform 0.5s ease",
  };

  return (
    <>
      <LazyLoad>
        <Box
          component="img"
          src={src}
          sx={{
            ...sx,
            height: height,
            maxHeight: maxHeight ?? "100%",
            display: "block",
            width: width,
          }}
          style={styles}
          onMouseEnter={hovered ? handleHover : null}
          onMouseLeave={hovered ? handleUnhover : null}
          onLoad={onLoad}
          onClick={onClick}
        />
      </LazyLoad>
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  onLoad: PropTypes.func,
  sx: PropTypes.object,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  width: PropTypes.string,
  hovered: PropTypes.bool,
};

Image.defaultProps = {
  src: "",
  onLoad: undefined,
  height: undefined,
  maxHeight: undefined,
  width: "100%",
  hovered: false,
};

export default Image;
