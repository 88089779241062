import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Message,
  CardGiftcard,
  Home,
  LocalAtm,
  Dehaze,
  Groups,
  Outbox,
  Person,
} from "@mui/icons-material";
import {
  Box,
  useMediaQuery,
  useTheme,
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { selectCurrentToken } from "../features/slices/authSlice";
import { selectAlertIsOpen } from "../features/slices/alertSlice";

import { useGetCommonBackgroundQuery } from "../features/api/publicApiSlice";

import DesktopHeader from "./Header/DesktopHeader";
import MobileHeader from "./Header/MobileHeader";
import Footer from "./Footer";
import SideNav from "./common/SideNav";
import SnackbarAlert from "./common/SnackbarAlert";

const Layout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const alertIsOpen = useSelector(selectAlertIsOpen);

  const {
    data: commonBackground,
    isLoading: isCommonBackgroundLoading,
    isSuccess: isCommonBackgroundSuccess,
  } = useGetCommonBackgroundQuery({ prefetch: true });

  const triggerLiveChat = () => {
    if (
      typeof window.LiveChatWidget !== "undefined" &&
      window.LiveChatWidget.call
    ) {
      window.LiveChatWidget.call("maximize");
    }
  };

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {alertIsOpen && <SnackbarAlert />}
      <SideNav />
      <header>
        {mobileView ? (
          <>
            {location.pathname !== "/register" &&
              location.pathname !== "/login" &&
              location.pathname !== "/game/slot" &&
              location.pathname !== "/game/live_casino" &&
              location.pathname !== "/game/fish_hunter" &&
              location.pathname !== "/game/sportbook" &&
              location.pathname !== "/promotion" &&
              location.pathname !== "/forgotPassword" &&
              location.pathname !== "/resetPassword" &&
              location.pathname !== "/contactus" &&
              location.pathname !== "/language" &&
              location.pathname !== "/about" &&
              location.pathname !== "/terms" && (
                <MobileHeader
                  background={
                    commonBackground?.mobileBackground[0]?.thumbnailUrl
                  }
                />
              )}
          </>
        ) : (
          <DesktopHeader
            background={commonBackground?.webBackground[0]?.thumbnailUrl}
          />
        )}
      </header>
      <Box flex={1}>
        <Outlet />
      </Box>
      {!mobileView && (
        <footer>
          <Footer
            background={commonBackground?.webBackground[1]?.thumbnailUrl}
            contactDetails={commonBackground?.result}
          />
        </footer>
      )}
      {mobileView &&
        location.pathname !== "/register" &&
        location.pathname !== "/login" &&
        location.pathname !== "/game/slot" &&
        location.pathname !== "/game/live_casino" &&
        location.pathname !== "/game/fish_hunter" &&
        location.pathname !== "/game/sportbook" &&
        location.pathname !== "/forgotPassword" &&
        location.pathname !== "/resetPassword" &&
        location.pathname !== "/contactus" &&
        location.pathname !== "/language" &&
        location.pathname !== "/about" &&
        location.pathname !== "/terms" && (
          <AppBar
            position="fixed"
            style={{
              top: "auto",
              bottom: 0,
            }}
          >
            <BottomNavigation
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
              showLabels
              // value={value}
              onChange={(event, newValue) => {
                if (newValue === 0) {
                  navigate("/");
                } else if (newValue === 1) {
                  navigate("/promotion");
                } else if (newValue === 2) {
                  if (token) {
                    navigate("/profileWallet", {
                      state: {
                        screen: "Deposit",
                      },
                    });
                  } else {
                    navigate("/login");
                  }
                } else if (newValue === 3) {
                  if (token) {
                    navigate("/profileWallet", {
                      state: {
                        screen: "Withdrawal",
                      },
                    });
                  } else {
                    navigate("/login");
                  }
                } else if (newValue === 4) {
                  //triggerLiveChat();
                  navigate("/profileWallet");

                }
              }}
            >
              <BottomNavigationAction
                sx={{ color: "white" }}
                label={t("footer.home")}
                icon={<Home />}
              />
              <BottomNavigationAction
                sx={{ color: "white" }}
                label={t("footer.promotion")}
                icon={<CardGiftcard />}
              />
              <BottomNavigationAction
                sx={{ color: "white" }}
                label={t("footer.deposit")}
                icon={<LocalAtm />}
              />
              <BottomNavigationAction
                sx={{ color: "white" }}
                label={t("footer.withdrawal")}
                icon={<Outbox />}
              />
              {/*<BottomNavigationAction
                sx={{ color: "white" }}
                label={t("footer.livechat")}
                icon={<Message />}
              />*/}
              <BottomNavigationAction
                  sx={{ color: "white" }}
                  label={t("footer.account")}
                  icon={<Person />}
              />
            </BottomNavigation>
          </AppBar>
        )}
    </Box>
  );
};

export default Layout;
