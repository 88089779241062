import { useNavigate } from "react-router-dom";
import {
  Check,
  Email,
  Facebook,
  Instagram,
  PhoneInTalk,
  SupportAgent,
  Telegram,
  WhatsApp,
  YouTube,
  ArrowBack,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const About = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {mobileView && (
        <>
          <Grid container justify="center" pt={1} pb={5}>
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("aboutus.about.title")}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <>
        <Container mt={mobileView ? 0 : null}>
          <Box my={!mobileView && 4}>
            {/* About us */}
            <Typography
              variant="h6"
              component="h6"
              fontWeight={800}
              color={theme.palette.primary.main}
            >
              {t("aboutus.about.title")}
            </Typography>
            <Typography variant="body2" component="div">
              {t("aboutus.about.firstdesc")}
            </Typography>
            <br />
            <Typography variant="body2" component="div">
              {t("aboutus.about.seconddesc")}
            </Typography>
            <br />
            <Typography variant="body2" component="div">
              {t("aboutus.about.thirddesc")}
            </Typography>
            <br />
            <Typography variant="body2" component="div">
              {t("aboutus.about.fourthdesc")}
            </Typography>
          </Box>
        </Container>
      </>
    </>
  );
};

export default About;
