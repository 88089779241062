import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import getDesignTokens from "./utils/theme";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./utils/i18n";

import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";

import Home from "./pages/Home";
import Game from "./pages/Game";
import Bonus from "./pages/Bonus";
import Vip from "./pages/Vip";
import Affiliate from "./pages/Affiliate";
import ProfileWallet from "./pages/ProfileWallet";
import About from "./pages/About";
import Terms from "./pages/Terms";
import Rules from "./pages/Rules";
import Faq from "./pages/Faq";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ContactUs from "./pages/ContactUs";
import Language from "./pages/Language";
import Backdrop from "./components/common/Backdrop";
import HowToJoin from "./pages/HowToJoin";
import HowToDeposit from "./pages/HowToDeposit";
import HowToWithdraw from "./pages/HowToWithdraw";
import ResponsibleGaming from "./pages/ResponsibleGaming";
import KYCVerification from "./pages/KYCVerification";
import AntiScam from "./pages/AntiScam";
import ModalDialog from "./components/common/ModalDialog";

function App() {
  const theme = createTheme(getDesignTokens());

  setTimeout(() => {
    var statedata = localStorage.getItem("persist:root");
    if (statedata.includes("\\\"isOpen\\\":true")) {
      var newstatedata = statedata.replace("\\\"isOpen\\\":true", "\\\"isOpen\\\":false");
      localStorage.setItem("persist:root", newstatedata);
      window.location.reload();
    }
  }, 1000);
  
  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ScrollToTop />
          <ModalDialog />
          <Suspense fallback={<Backdrop />}>
            <Routes>
              <Route element={<Layout />}>
                <Route path="*" element={<Home />} />
                <Route index element={<Home />} />
                <Route path="/game/:gameCategory" exact element={<Game />} />
                <Route path="promotion" exact element={<Bonus />} />
                {/* <Route path="affiliate" exact element={<Affiliate />} /> */}
                <Route path="/register" exact element={<Register />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="vip" exact element={<Vip />} />
                <Route path="about" exact element={<About />} />
                <Route path="terms" exact element={<Terms />} />
                <Route path="rules" exact element={<Rules />} />
                <Route path="faq" exact element={<Faq />} />
                <Route path="contactus" exact element={<ContactUs />} />
                <Route path="language" exact element={<Language />} />
                <Route path="howToJoin" exact element={<HowToJoin />} />
                <Route path="howToDeposit" exact element={<HowToDeposit />} />
                <Route path="howToWithdraw" exact element={<HowToWithdraw />} />
                <Route
                  path="responsibleGaming"
                  exact
                  element={<ResponsibleGaming />}
                />
                <Route
                  path="kycVerification"
                  exact
                  element={<KYCVerification />}
                />
                <Route path="antiScam" exact element={<AntiScam />} />
                <Route
                  path="/forgotPassword"
                  exact
                  element={<ForgotPassword />}
                />
                <Route
                  path="/resetPassword"
                  exact
                  element={<ResetPassword />}
                />
                <Route element={<RequireAuth />}>
                  <Route
                    path="/profileWallet/:tab?"
                    exact
                    element={<ProfileWallet />}
                  />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </ThemeProvider>
      </LocalizationProvider>
    </I18nextProvider>
  );
}

export default App;
