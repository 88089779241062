import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import {
  Container,
  Box,
  useTheme,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Pagination,
  Stack,
  TextField,
  IconButton,
  darken,
  Divider,
  InputAdornment,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { openModal, closeModal } from "../features/slices/modalSlice";
import CopiedTooltip from "../components/common/CopiedTooltip";

import { Close, ArrowBack, CopyAll } from "@mui/icons-material";

import { selectCurrentToken } from "../features/slices/authSlice";

import {
  useGetGameProviderQuery,
  useLazyGetGameItemQuery,
  useLazyGetLaunchGameQuery,
  useGetGameCategoryQuery,
} from "../features/api/gameApiSlice";

import GameCard from "../components/Game/GameCard";
import Backdrop from "../components/common/Backdrop";
import GameProviderDrawer from "../components/Game/GameProviderDrawer";
import TextCarousel from "../components/common/TextCarousel";
import Image from "../components/common/Image";
import SearchBar from "../components/common/SearchBar";

const pattern = /^((http|https|ftp):\/\/)/;

const Game = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(selectCurrentToken);
  const { gameCategory } = useParams();
  const [offset, setOffset] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [group, setGroup] = useState("ALL");
  const [currentProviderId, setCurrentProviderId] = useState("");
  const [appProviderName, setAppProviderName] = useState("");
  const [gameProviderDrawer, setGameProviderDrawer] = useState(false);
  const [appGameDisplay, setAppGameDisplay] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));

  const liveCasinoSeo = [
    <>
      <Typography fontWeight={800}>{t("game.casinoseo.firsttitle")}</Typography>
      <Typography mt={2}>{t("game.casinoseo.firstdesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>
        {t("game.casinoseo.secondtitle")}
      </Typography>
      <Typography mt={2}>{t("game.casinoseo.seconddesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>{t("game.casinoseo.thirdtitle")}</Typography>
      <Typography mt={2}>{t("game.casinoseo.thirddesc")}</Typography>
    </>,
  ];

  const slotSeo = [
    <>
      <Typography fontWeight={800}>{t("game.slotseo.firsttitle")}</Typography>
      <Typography mt={2}>{t("game.slotseo.firstdesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>{t("game.slotseo.secondtitle")}</Typography>
      <Typography mt={2}>{t("game.slotseo.seconddesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>{t("game.slotseo.thirdtitle")}</Typography>
      <Typography mt={2}>{t("game.slotseo.thirddesc")}</Typography>
    </>,
  ];

  const sportbookSeo = [
    <>
      <Typography fontWeight={800}>
        {t("game.sportbookseo.firsttitle")}
      </Typography>
      <Typography mt={2}>{t("game.sportbookseo.firstdesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>
        {t("game.sportbookseo.secondtitle")}
      </Typography>
      <Typography mt={2}>{t("game.sportbookseo.seconddesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>
        {t("game.sportbookseo.thirdtitle")}
      </Typography>
      <Typography mt={2}>{t("game.sportbookseo.thirddesc")}</Typography>
    </>,
  ];

  const fishHunterSeo = [
    <>
      <Typography fontWeight={800}>
        {t("game.fishingseo.firsttitle")}
      </Typography>
      <Typography mt={2}>{t("game.fishingseo.firstdesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>
        {t("game.fishingseo.secondtitle")}
      </Typography>
      <Typography mt={2}>{t("game.fishingseo.seconddesc")}</Typography>
    </>,
    <>
      <Typography fontWeight={800}>
        {t("game.fishingseo.thirdtitle")}
      </Typography>
      <Typography mt={2}>{t("game.fishingseo.thirddesc")}</Typography>
    </>,
  ];

  const {
    data: allGameCategory,
    isFetching: isAllGameCategoryFetching,
    isLoading: isAllGameCategoryLoading,
  } = useGetGameCategoryQuery({ prefetch: true });

  const {
    data: gameProvider,
    isFetching: isGameProviderFetching,
    isLoading: isGameProviderLoading,
  } = useGetGameProviderQuery(gameCategory, { prefetch: true });

  const [
    getGameItem,
    {
      data: gameItem,
      isFetching: isGameItemFetching,
      isLoading: isGameItemLoading,
      isError: isGameItemError,
      isSuccess: isGameItemSuccess,
      error: gameItemError,
    },
  ] = useLazyGetGameItemQuery({ prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  useEffect(() => {
    setAppGameDisplay(false);
    getGameItem({
      category: gameCategory,
      offset: offset - 1,
      providerId: currentProviderId || "",
      keyword: searchQuery || "",
      group: group || "ALL",
    });
  }, [offset, currentProviderId, gameCategory, searchQuery, group]);

  useEffect(() => {
    setOffset(1);
  }, [currentProviderId]);

  useEffect(() => {
    setCurrentProviderId(location?.state?.providerId);
  }, [location?.state?.providerId]);

  useEffect(() => {
    setCurrentProviderId(
      location?.state?.providerId || gameProvider?.result[0]?.id
    );
    if (
      allGameCategory?.result?.find(
        (cat) => cat.code?.toLowerCase() === gameCategory
      )?.hasGameList &&
      gameProvider?.result[0]?.grouping
    ) {
      setGroup(gameProvider?.result[0]?.grouping[0]?.code || "");
    }
  }, [gameProvider]);

  useEffect(() => {
    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "APP") {
        setAppGameDisplay(true);
      } else if (launchGame?.result?.gameType === "WEB") {
        //window.open(launchGame.result.gameUrl, "_blank");
        if (launchGame?.result?.iframeSupported == true) {
          window.location.href = process.env.REACT_APP_GAME_URL_IFRAME + launchGame.result.gameUrl;
        }
        else {
          window.open(launchGame.result.gameUrl, "_blank");
        }

      }
    }
  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess]);

  useEffect(() => {
    if (isGameItemError) {
      dispatch(
        openModal({
          title: gameItemError?.data?.message,
        })
      );
    }
  }, [isGameItemError]);

  useEffect(() => {
    if (isLaunchGameError) {
      dispatch(
        openModal({
          title: launchGameError?.data?.message,
        })
      );
    }
  }, [isLaunchGameError]);

  const handlePageChange = (e, p) => {
    setOffset(p);
    window.scroll(0, 0);
  };

  return (
    <>
      {/* Banner Section */}
      <Box>
        {!mobileView && (
          <Image
            src={
              allGameCategory?.result?.find(
                (cat) => cat.code?.toLowerCase() === gameCategory
              )?.bannerUrl
            }
          />
        )}
      </Box>
      {/* Game Provider Filter */}
      <Box
        pt={{ xs: 1, sm: 4, lg: 4, xl: 4 }}
        sx={{
          backgroundColor:
            location.pathname !== "/sportbook" &&
            location.pathname !== "/live_casino" &&
            theme.palette.background.paper,
        }}
      >
        {mobileView && (
          <Box pb={2}>
            <Grid container justifyContent="space-between">
              <IconButton onClick={() => navigate("/")}>
                <ArrowBack />
              </IconButton>
              <SearchBar setSearchQuery={setSearchQuery} />
            </Grid>
          </Box>
        )}
        <Container>
          {mobileView ? (
            <>
              <Button
                onClick={() => setGameProviderDrawer(true)}
                variant="outlined"
                fullWidth
                sx={{
                  "&.MuiButtonBase-root:hover": {
                    backgroundColor: "transparent",
                  },
                  borderColor: theme.palette.primary.main,
                  borderRadius: 4,
                  maxWidth: "100%",
                }}
                disableRipple
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    textTransform: "UPPERCASE",
                    fontWeight: "600",
                  }}
                >
                  {t("game.gameproviders")}
                </Typography>
                <KeyboardDoubleArrowRight />
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    textTransform: "UPPERCASE",
                    fontWeight: "600",
                  }}
                >
                  {gameProvider?.result?.find(
                    (provider) => provider.id === currentProviderId
                  )?.name || "All"}
                </Typography>
              </Button>
            </>
          ) : (
            <>
              {allGameCategory?.result?.find(
                (cat) => cat.code?.toLowerCase() === gameCategory
              )?.hasGameList && (
                <>
                  <Grid container spacing={2}>
                    {gameProvider?.result?.map((provider, index) => (
                      <Grid key={index} item>
                        <Box
                          sx={{
                            border: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 40,
                            width: 120,
                            boxSizing: "border-box",
                            // maxWidth: "100%",
                            wordWrap: "break-word",
                            cursor: "pointer",
                            backgroundColor:
                              currentProviderId === provider.id
                                ? theme.palette.secondary.main
                                : theme.palette.background.paper,
                            borderColor: darken(
                              theme.palette.background.paper,
                              0.2
                            ),
                          }}
                          onClick={() => {
                            setCurrentProviderId(provider.id);
                            setGroup("ALL");
                          }}
                        >
                          <Image
                            src={
                              currentProviderId === provider.id
                                ? provider.logoSelectedUrl
                                : provider.logoUrl
                            }
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </>
          )}
        </Container>
      </Box>
      {/* All, Hot & Other Categories with search bar */}
      {allGameCategory?.result?.find(
        (cat) => cat.code?.toLowerCase() === gameCategory
      )?.hasGameList && (
        <Box
          sx={{
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            pt: !mobileView ? 3 : 1,
            pb: !mobileView ? 1.2 : 0,
            px: !mobileView ? 1.2 : 0,
          }}
        >
          <Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: !mobileView ? "space-between" : "center",
              }}
            >
              <Box display="flex">
                {gameProvider?.result
                  ?.find((provider) => provider.id === currentProviderId)
                  ?.grouping?.map((grouping, index) => (
                    <Button
                      onClick={() => setGroup(grouping?.code)}
                      sx={{
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      disableRipple
                    >
                      {group === grouping?.code && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            left: "50%",
                            transform: "translateX(-50%)",
                            borderBottom: "2px solid",
                            width: "50%",
                          }}
                        />
                      )}
                      <Typography>{grouping?.label}</Typography>
                    </Button>
                  ))}
              </Box>
              {!mobileView && <SearchBar setSearchQuery={setSearchQuery} />}
            </Box>
          </Container>
        </Box>
      )}
      <Container>
        {/* Mobile View Filter Drawer */}
        <GameProviderDrawer
          gameProviderDrawer={gameProviderDrawer}
          setGameProviderDrawer={setGameProviderDrawer}
          gameProvider={gameProvider?.result}
          currentProviderId={currentProviderId}
          setOffset={setOffset}
          setCurrentProviderId={setCurrentProviderId}
        />
        {/* App Game PopUp */}
        {(isLaunchGameLoading || isLaunchGameFetching) && <Backdrop />}
        {!isLaunchGameLoading &&
          !isLaunchGameFetching &&
          isLaunchGameSuccess && (
            <>
              {appGameDisplay && (
                <>
                  <Box display="flex" flexDirection="column" pt={2}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignSelf="center"
                      pt={2}
                    >
                      <Image src={launchGame?.result?.bannerUrl} />
                      <Typography color={theme.palette.primary.main} pt={2}>
                        {`EASY STEP BY STEP TO PLAY ${appProviderName} ON YOUR MOBILE`}
                      </Typography>
                      <Typography color={theme.palette.primary.main} pt={2}>
                        Step 1
                      </Typography>
                      <Typography>
                        {`Transfer fund into ${appProviderName} wallet to get your login ID and passwordon the below section of this page.`}
                      </Typography>
                      <Typography color={theme.palette.primary.main} pt={2}>
                        Step 2
                      </Typography>
                      <Typography>
                        {`Download ${appProviderName} from the download link below.`}
                      </Typography>
                      <Typography color={theme.palette.primary.main} pt={2}>
                        Step 3
                      </Typography>
                      <Typography pb={3}>
                        {`Launch the apps in your mobile and login with the ID and password from below.`}
                      </Typography>
                      <Divider />
                      <FormControl
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          pt: 4,
                          "& .MuiTextField-root": {
                            pb: 3,
                          },
                        }}
                      >
                        <TextField
                          label="Username"
                          value={launchGame?.result?.mobileAppUsername}
                          InputLabelProps={{ shrink: true }}
                          disabled
                          type="text"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disableRipple
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <CopiedTooltip
                                    text={launchGame?.result?.mobileAppUsername}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          label="Password"
                          value={launchGame?.result?.mobileAppPassword}
                          InputLabelProps={{ shrink: true }}
                          disabled
                          type="text"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disableRipple
                                  sx={{
                                    "&.MuiButtonBase-root:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <CopiedTooltip
                                    text={launchGame?.result?.mobileAppPassword}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box display="flex" alignSelf="center">
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.button.primary.main,
                        }}
                        onClick={() => {
                          let url = launchGame.result.mobileAppUrlIos[0];
                          if (
                            !pattern.test(launchGame.result.mobileAppUrlIos[0])
                          ) {
                            url = "https://" + url;
                          }
                          window.open(url, "_blank");
                        }}
                      >
                        IOS
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.button.primary.main,
                          ml: 2,
                        }}
                        onClick={() => {
                          let url = launchGame.result.mobileAppUrlAndroid[0];
                          if (
                            !pattern.test(
                              launchGame.result.mobileAppUrlAndroid[0]
                            )
                          ) {
                            url = "https://" + url;
                          }
                          window.open(url, "_blank");
                        }}
                      >
                        ANDROID
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        {/* Game Item List */}
        <Box pb={mobileView && 5}>
          {!isGameItemLoading &&
            isGameItemSuccess &&
            allGameCategory?.result?.find(
              (cat) => cat.code?.toLowerCase() === gameCategory
            )?.hasGameList && (
              <>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  mt={mobileView ? 0 : 1}
                >
                  {gameItem?.result?.data?.map((game, index) => (
                    <>
                      {!appGameDisplay && (
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={1.5}
                          key={index}
                          display="flex"
                          justifyContent="center"
                        >
                          <GameCard
                            imageUrl={game?.thumbnailUrl}
                            imageWidth={
                              mobileView
                                ? "113px"
                                : tabletView
                                ? "122px"
                                : "131px"
                            }
                            imageHeight={
                              mobileView
                                ? "113px"
                                : tabletView
                                ? "122px"
                                : "131px"
                            }
                            cardStyle={{
                              cursor: "pointer",
                              bgcolor: theme.palette.background.paper,
                              pb: 2,
                              borderRadius: 3,
                              borderWidth: 0,
                              mb: 1,
                            }}
                            onClick={() => {
                              if (!token) {
                                dispatch(
                                  openModal({
                                    title: t("dialog.proceedtologin"),
                                    button: [
                                      {
                                        label: t("dialog.login"),
                                        onSubmit: () => {
                                          dispatch(closeModal());
                                          if (mobileView) {
                                            navigate("/login");
                                          }
                                        },
                                      },
                                    ],
                                  })
                                );
                              } else {
                                // if (game?.disabled) {
                                //   dispatch(
                                //     openModal({
                                //       title: t("game.gameunavailable"),
                                //     })
                                //   );
                                // } else {
                                setAppProviderName(
                                  gameProvider?.result?.find(
                                    (provider) =>
                                      provider?.id === game?.providerId
                                  )?.name
                                );
                                getLaunchGame({
                                  id: game?.id,
                                  type: game?.gameType,
                                });
                                // }
                              }
                            }}
                            title={game?.name}
                            subtitle={
                              gameProvider?.result?.find(
                                (provider) => provider?.id === game?.providerId
                              )?.name
                            }
                            description={{}}
                          />
                        </Grid>
                      )}
                    </>
                  ))}
                </Grid>
                {gameItem?.result?.totalPage !== 0 && !appGameDisplay && (
                  <Stack
                    mt={tabletView ? 0 : 2}
                    spacing={2}
                    size="small"
                    alignItems="center"
                  >
                    <Pagination
                      onChange={handlePageChange}
                      count={gameItem?.result?.totalPage}
                      shape="rounded"
                      page={offset}
                    />
                  </Stack>
                )}
              </>
            )}
          {!allGameCategory?.result?.find(
            (cat) => cat.code?.toLowerCase() === gameCategory
          )?.hasGameList && (
            <Grid container spacing={3}>
              {gameProvider?.result?.map((provider, index) => (
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={2}
                  key={index}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!token) {
                      dispatch(
                        openModal({
                          title: t("dialog.proceedtologin"),
                          button: [
                            {
                              label: t("dialog.login"),
                              onSubmit: () => dispatch(closeModal()),
                            },
                          ],
                        })
                      );
                    } else {
                      setAppProviderName(provider?.name);
                      getLaunchGame({
                        id: provider?.id,
                        type: provider?.gameType,
                        category: gameCategory,
                      });
                    }
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box overflow="hidden">
                      <Image hovered={true} src={provider.providerImgUrl} />
                    </Box>
                    <Typography
                      color={theme.palette.primary.main}
                      fontSize={13}
                      fontWeight={800}
                      mt={2}
                    >
                      {provider.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
        {!mobileView && (
          <Box py={5}>
            <TextCarousel
              html={
                gameCategory === "live_casino"
                  ? liveCasinoSeo
                  : gameCategory === "slot"
                  ? slotSeo
                  : gameCategory === "sportbook"
                  ? sportbookSeo
                  : gameCategory === "fish_hunter"
                  ? fishHunterSeo
                  : null
              }
              slidesPerView={1}
              pagination={true}
            />
          </Box>
        )}
      </Container>
    </>
  );
};

export default Game;
