import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  Button,
  Typography,
  Popper,
  Paper,
  darken,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Box,
  IconButton,
  alpha,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { openModal, closeModal } from "../../features/slices/modalSlice";

import Image from "../common/Image";

import { selectCurrentToken } from "../../features/slices/authSlice";

import {
  useLazyGetGameProviderQuery,
  useLazyGetLaunchGameQuery,
  useGetGameCategoryQuery,
} from "../../features/api/gameApiSlice";
import Backdrop from "../common/Backdrop";
import * as Constant from "../../features/constant";

const DesktopHeaderItems = ({ label, code, selectedPath, setSelectedPath }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  const {
    data: gameCategory,
    isFetching: isGameCategoryFetching,
    isLoading: isGameCategoryLoading,
    isError: isGameCategoryError,
    isSuccess: isGameCategorySuccess,
    error: gameCategoryError,
  } = useGetGameCategoryQuery({ prefetch: true });

  const [
    getGameProvider,
    {
      data: gameProvider,
      isFetching: isGameProviderFetching,
      isLoading: isGameProviderLoading,
      isError: isGameProviderError,
      isSuccess: isGameProviderSuccess,
      error: gameProviderError,
    },
  ] = useLazyGetGameProviderQuery("", { prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  useEffect(() => {
    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "WEB") {
        //window.open(launchGame.result.gameUrl, "_blank");
        if (launchGame?.result?.iframeSupported == true) {
          window.location.href = process.env.REACT_APP_GAME_URL_IFRAME + launchGame.result.gameUrl;
        }
        else {
          window.open(launchGame.result.gameUrl, "_blank");
        }

      }
    }
  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess]);

  useEffect(() => {
    setAnchorEl(null);
    setPopperOpen(false);
  }, []);

  const handleAnchorEnter = (event, code) => {
    getGameProvider(code);
    setAnchorEl(event.currentTarget);
    setPopperOpen(true);
  };

  const handleAnchorLeave = () => {
    setAnchorEl(null);
    setPopperOpen(false);
  };

  const handlePopperEnter = () => {
    setPopperOpen(true);
  };

  const handlePopperLeave = () => {
    setPopperOpen(false);
  };

  if (isLaunchGameFetching || isLaunchGameLoading) {
    return <Backdrop />;
  }

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: selectedPath ? theme.palette.secondary.main : "white",
          pt: 0,
          ":hover": {
            color: theme.palette.secondary.main,
            borderBottom: selectedPath && 3,
          },
          borderBottom: selectedPath && 3,
          borderRadius: 0,
        }}
        disableRipple
        onClick={() => {
          navigate(
            label === t("header.home")
              ? "/"
              : code?.toLowerCase() === "slot"
              ? "/game/slot"
              : code?.toLowerCase() === "live_casino"
              ? "/game/live_casino"
              : code?.toLowerCase() === "sportbook"
              ? "/game/sportbook"
              : code?.toLowerCase() === "fish_hunter"
              ? "/game/fish_hunter"
              : label === t("header.promotion")
              ? "/promotion"
              : label === t("header.vip")
              ? "/vip"
              : null
          );
          setSelectedPath(
            label === t("header.home")
              ? "/"
              : code?.toLowerCase() === "slot"
              ? "/slot"
              : code?.toLowerCase() === "live_casino"
              ? "/live_casino"
              : code?.toLowerCase() === "sportbook"
              ? "/sportbook"
              : code?.toLowerCase() === "fish_hunter"
              ? "/fish_hunter"
              : label === t("header.promotion")
              ? "/promotion"
              : label === t("header.vip")
              ? "/vip"
              : null
          );
        }}
        onMouseEnter={(event) => handleAnchorEnter(event, code)}
        onMouseLeave={handleAnchorLeave}
      >
        <Typography
          display="block"
          variant="sublabel1"
          textAlign="center"
          fontWeight={600}
        >
          {label}
        </Typography>
      </Button>
      {(code === "SLOT" ||
        code === "LIVE_CASINO" ||
        code === "SPORTBOOK" ||
        code === "FISH_HUNTER") && (
        <Popper
          id="mouse-over-popover"
          open={popperOpen}
          anchorEl={anchorEl}
          placement="bottom"
          sx={{
            zIndex: 1,
            width: "100%",
          }}
        >
          <Paper
            elevation={4}
            sx={{
              p: 2,
              backgroundColor: alpha("#000000", 0.5),
              width: "100%",
            }}
            onMouseEnter={handlePopperEnter}
            onMouseLeave={handlePopperLeave}
          >
            {!isGameProviderFetching &&
              !isGameProviderLoading &&
              isGameProviderSuccess && (
                <>
                  <Grid
                    container
                    spacing={0}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {gameProvider?.result?.map((provider, idx) => (

                        <Box
                            m={1}
                            onClick={() => {
                              let hasGameList = gameCategory?.result?.find(
                                  (category) => category.code === code
                              )?.hasGameList;
                              if (hasGameList) {
                                navigate(Constant.getPath(`/game/${code.toLowerCase().replaceAll("_", "-")}/${provider.name.replaceAll(" ", "-").toLowerCase()}`), {
                                  state: {
                                    providerId: provider?.id,
                                  },
                                  replace: true,
                                });
                              } else {
                                if (!token) {
                                  dispatch(
                                      openModal({
                                        title: t("dialog.proceedtologin"),
                                        button: [
                                          {
                                            label: t("dialog.login"),
                                            onSubmit: () => dispatch(closeModal()),
                                          },
                                        ],
                                      })
                                  );
                                } else {
                                  getLaunchGame({
                                    id: provider?.id,
                                    type: provider?.gameType,
                                    category: code,
                                    isMobile: mobileView
                                  });
                                }
                              }
                              handlePopperLeave();
                          }}
                        >
                          <Image src={provider?.navIconUrl} />
                        </Box>
                    ))}
                  </Grid>
                </>
              )}
          </Paper>
        </Popper>
      )}
    </>
  );
};

export default DesktopHeaderItems;
