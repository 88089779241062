import React from "react";
import {
  Typography,
  Box,
  FormControl,
  TextField,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CopiedTooltip from "../common/CopiedTooltip";

const UserProfile = ({ user, shareLink }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Typography fontWeight="bold">{t("walletcard.myaccount")}</Typography>
      <Box display="flex" flexDirection="column">
        <form>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: "15px",
              "& .MuiTextField-root": {
                pb: "15px",
              },
            }}
          >
            <TextField
              label={t("walletcard.myaccountpage.username")}
              value={user?.username || ""}
              disabled
              type="text"
              size="small"
            />
            <TextField
              label={t("walletcard.myaccountpage.fullname")}
              value={user?.fullname || ""}
              disabled
              type="text"
              size="small"
            />
            <TextField
              label={t("walletcard.myaccountpage.dob")}
              value={user?.dateOfBirth || ""}
              disabled
              type="text"
              size="small"
            />
            <TextField
              label={t("walletcard.myaccountpage.email")}
              value={user?.email || ""}
              disabled
              type="text"
              size="small"
            />
            <TextField
              label={t("walletcard.myaccountpage.mobilenumber")}
              value={user?.mobileNumber || ""}
              disabled
              type="text"
              size="small"
            />
            <TextField
              label={t("walletcard.myaccountpage.referrallink")}
              value={shareLink}
              InputLabelProps={{ shrink: true }}
              disabled
              type="text"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <CopiedTooltip text={shareLink} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </form>
      </Box>
    </Container>
  );
};

export default UserProfile;
