import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  Box,
  Divider,
  List,
  ListItemButton,
  IconButton,
  useTheme,
  Typography,
} from "@mui/material";
import { Menu, Language, Call } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../icons/header/HomeIcon";
import PromotionIcon from "../../icons/header/PromotionIcon";
import VipIcon from "../../icons/header/VipIcon";

import UserCard from "./UserCard";

const drawerWidth = 220;

const Drawer = ({ user, window }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const drawerItems = [
    {
      label: t("drawer.home"),
      code: "home",
      icon: (
        <HomeIcon width={25} height={25} color={theme.palette.primary.main} />
      ),
    },
    {
      label: t("drawer.promotion"),
      code: "promotion",
      icon: (
        <PromotionIcon
          width={25}
          height={25}
          color={theme.palette.primary.main}
        />
      ),
    },
    {
      label: t("drawer.vip"),
      code: "vip",
      icon: (
        <VipIcon width={25} height={25} color={theme.palette.primary.main} />
      ),
    },
    {
      label: t("drawer.language"),
      code: "language",
      icon: (
        <Language
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      ),
    },
    {
      label: t("drawer.contactus"),
      code: "contactus",
      icon: (
        <Call
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      ),
    },
    // {
    //   label: "Affiliate",
    //   icon: <Diversity3 sx={{ display: "block", width: "100%", mb: 1 }} />,
    // },
  ];

  const handleDrawerToggle = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  // const handleGameDrawerToggle = () => {
  //   setGameDrawerOpen(!gameDrawerOpen);
  // };

  const drawerContent = (
    <>
      <Box>
        <UserCard
          isDrawer={true}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          user={user}
        />
      </Box>
      <Divider
        sx={{ bgcolor: theme.palette.primary.main, height: "5px"}}
      />
      <List sx={{ justifyContent: "center", alignSelf: "center" }}>
        {drawerItems.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={(event) => {
              navigate(
                item?.code?.toLowerCase() === "home"
                  ? "/"
                  : "/" + item?.code?.toLowerCase().replace(/\s+/g, "")
              );
              handleDrawerToggle(event);
            }}
            sx={{ width: "100%", mb: 0 }}
            disableRipple
          >
            <>
              {item.icon}
              <Typography
                display="block"
                variant="sublabel1"
                textAlign="center"
                ml={3}
                lineHeight={0}
                fontWeight={600}
                fontSize={16}
              >
                {item.label}
              </Typography>
            </>
          </ListItemButton>
        ))}
      </List>
    </>
  );

  return (
    <>
      <IconButton
        sx={{ color: theme.palette.primary.main }}
        edge="start"
        onClick={handleDrawerToggle}
      >
        <Menu />
      </IconButton>
      <MuiDrawer
        container={container}
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: theme.palette.background.paper,
          },
        }}
      >
        {drawerContent}
      </MuiDrawer>
    </>
  );
};

Drawer.propTypes = {
  user: PropTypes.object,
};

Drawer.defaultProps = {
  user: {},
};

export default Drawer;
