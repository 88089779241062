import React from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Container,
  useTheme,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import FormTextField from "../components/form/FormTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../features/slices/alertSlice";
import { useTranslation } from "react-i18next";

import { useLazyGetForgotPasswordQuery } from "../features/api/publicApiSlice";

const ForgotPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const forgotPasswordSchema = yup.object().shape({
    forgotPassword: yup
      .string()
      .email(t("validation.invalidemail"))
      .required(t("validation.emailisrequired")),
  });

  const { control: forgotPasswordControl, handleSubmit } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const [
    getForgotPassword,
    { data, isFetching, isLoading, isError, isSuccess, error, reset },
  ] = useLazyGetForgotPasswordQuery();

  const submitEmail = async (data) => {
    await getForgotPassword(data?.forgotPassword);
  };

  if (!isLoading && isSuccess) {
    dispatch(
      setAlert({
        isOpen: true,
        message: t("forgotpassword.successmessage"),
        variant: "success",
      })
    );
  }

  if (isError) {
    dispatch(
      setAlert({
        isOpen: true,
        message: error.data.message,
        variant: "error",
      })
    );
  }

  return (
    <>
      {mobileView && (
        <Grid container justify="center" py={1}>
          <Grid item xs={4}>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={4} alignSelf="center">
            <Typography
              textAlign="center"
              fontWeight={600}
              color={theme.palette.primary.main}
            >
              {t("forgotpassword.title")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Container>
        {!mobileView && (
          <Typography
            fontWeight={800}
            fontSize={14}
            color={theme.palette.primary.main}
            mt={5}
            mb={2}
          >
            {t("forgotpassword.title")}
          </Typography>
        )}
        <Box
          py={3}
          mt={mobileView && 5}
          px={mobileView ? 10 : 40}
          // mb={5}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Typography fontSize={14}>{t("forgotpassword.desc")}</Typography>
          <form onSubmit={handleSubmit(submitEmail)}>
            <FormTextField
              label={t("forgotpassword.email")}
              defaultValue={""}
              control={forgotPasswordControl}
              controllerName="forgotPassword"
              type="text"
              size="small"
            />
            <Box display="flex" justifyContent="center" mt="30px">
              <Button variant="contained" type="submit">
                {t("forgotpassword.submit")}
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
