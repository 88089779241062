import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const AccordionComponent = ({ title, children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<KeyboardArrowDown />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  title: PropTypes.string,
  children: PropTypes.string,
};

AccordionComponent.defaultProps = {
  title: null,
  children: null,
};

export default AccordionComponent;
