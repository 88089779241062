import {
  Box,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import AccordionComponent from "../components/common/Accordion";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [tab, setTab] = useState(0);

  const faqs = [
    {
      title: t("faq.title"),
      faq: [
        {
          question: t("faq.1.title"),
          answer: t("faq.1.desc"),
        },
        {
          question: t("faq.2.title"),
          answer: t("faq.2.desc"),
        },
        {
          question: t("faq.3.title"),
          answer: t("faq.3.desc"),
        },
        {
          question: t("faq.4.title"),
          answer: t("faq.4.desc"),
        },
      ],
    },
  ];

  const tabChangeHandler = (e, value) => {
    setTab(value);
  };

  return (
    <>
      <Container>
        <Box my={4}>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tab}
                onChange={tabChangeHandler}
                aria-label="FAQ question categories"
                visibleScrollbar={false}
              >
                {faqs.map((a, i) => (
                  <Tab label={a.title} value={i} />
                ))}
              </Tabs>
            </Grid>
            <Grid item md={8}>
              <Box>
                <Typography variant="h5" color={theme.palette.primary.main}>
                  {faqs[tab].title}
                </Typography>
                <br />
                {faqs[tab].faq.map((a, i) => (
                  <AccordionComponent title={a.question}>
                    {a.answer}
                  </AccordionComponent>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Faq;
