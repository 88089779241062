const getDesignTokens = () => ({
  typography: {
    fontFamily: "Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Arial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  palette: {
    primary: {
      main: "#A20702",
      light: "#E77673",
    },
    secondary: {
      main: "#FDB813",
    },
    button: {
      primary: {
        main: "#A20702",
        dark: "#9E3A38",
        light: "#E77673",
      },
      secondary: {
        main: "#FDB813",
        dark: "#D6A106",
        light: "#FFE17F",
      },
      danger: {
        main: "#AE0000",
      },
      faded: {
        main: "#d8d8d8",
      }
    },
    background: {
      paper: "#F6F6F6",
      bonus: "#787878",
    },
    footer: {
      background: "#101419",
      text: "#F4EDED",
    },
  },
});

export default getDesignTokens;
