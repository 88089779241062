import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Container,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Image from "../components/common/Image";
import { useTranslation } from "react-i18next";
import { useGetLanguagesQuery } from "../features/api/publicApiSlice";

const Language = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleLanguageChange = () => {
      localStorage.setItem("language", i18n.language);
      navigate("/");
      window.location.reload();
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const {
    data: languages,
    isFetching: isLanguagesFetching,
    isLoading: isLanguagesLoading,
    isSuccess: isLanguagesSuccess,
  } = useGetLanguagesQuery({ prefetch: true });

  return (
    <>
      {mobileView && (
        <>
          <Grid container justify="center" pt={1} pb={5}>
            <Grid item xs={4}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <Typography
                textAlign="center"
                fontWeight={600}
                color={theme.palette.primary.main}
              >
                {t("language")}
              </Typography>
            </Grid>
          </Grid>
          <Container mt={0}>
            <Box display="flex" flexDirection="column" px={2}>
              <Typography>{t("translation.country")}</Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={1}
              >
                <Image
                  width="20px"
                  height="20px"
                  src={process.env.REACT_APP_SG_ICON}
                />
                <Box display="flex" pl={1}>
                  {languages?.result?.map((language) => (
                    <Typography
                      px={1}
                      sx={{
                        cursor: "pointer",
                        color:
                          i18n.language.toUpperCase() === language.code
                            ? "#1E90FF"
                            : "black",
                      }}
                      onClick={() => {
                        i18n.changeLanguage(language.code?.toLowerCase());
                      }}
                    >
                      {language?.label}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default Language;
