import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Divider, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  InputAdornment,IconButton
} from "@mui/material";
import { openModal, closeModal } from "../features/slices/modalSlice";
import FlipNumbers from "react-flip-numbers";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import { selectCurrentToken } from "../features/slices/authSlice";

import Image from "../components/common/Image";
import Announcement from "../components/common/Announcement";
import ImageCarousel from "../components/common/ImageCarousel";
import CardCarousel from "../components/common/CardCarousel";
import TextCarousel from "../components/common/TextCarousel";
import Backdrop from "../components/common/Backdrop";
import AccordionComponent from "../components/common/Accordion";

import SlotIcon from "../icons/header/SlotIcon";

import {
  useGetGameCategoryQuery,
  useLazyGetGameProviderQuery,
  useGetGameProviderQuery,
  useLazyGetLaunchGameQuery
} from "../features/api/gameApiSlice";
import { useGetHomePageQuery } from "../features/api/homeApiSlice";

import CasinoIcon from "../icons/header/CasinoIcon";
import SportIcon from "../icons/header/SportIcon";
import FishingIcon from "../icons/header/FishingIcon";
import PromotionIcon from "../icons/header/PromotionIcon";
import VipIcon from "../icons/header/VipIcon";
import WalletCard from "../components/common/WalletCard";
import DOMPurify from "dompurify";

import * as Constant from "../features/constant";
import CopiedTooltip from "../components/common/CopiedTooltip";
import { useGetShareLinkQuery } from "../features/api/userApiSlice";


const PATH = process.env.PUBLIC_URL;

const Home = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [popupOpen, setPopupOpen] = useState(true);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedGameCategory, setSelectedGameCategory] =
    useState("SLOT");
  const isLoggedIn = useSelector(selectCurrentToken);
  const [number, setNumber] = useState(1402000);
  const [openDialog, setOpenDialog] = useState(false); // State variable for dialog open/close

  const handleImageClick = () => {
    setOpenDialog(true); // Open the dialog when the image is clicked
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };


  const additionalGameBarItems = [
    { label: t("home.promotion"), code: "promotion" },
    { label: t("home.vip.title"), code: "vip" },
  ];

  const walletCardScreens = [
    {
      label: t("walletcard.deposit"),
      code: "Deposit",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`}
          alt={Constant.ALT_TAG_ICON_DEPOSIT}
        />
      ),
    },
    {
      label: t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={PATH + `/assets/img/wallet/wallet_card/Withdraw.png`}
          alt={Constant.ALT_TAG_ICON_WITHDRAW}
        />
      ),
    },
    {
      label: t("home.promotion"),
      code: "Promotion",
      icon: (
        <Image
          width="35px"
          height="35px"
          src={PATH + `/assets/img/wallet/wallet_card/Promotion.png`}
          alt={Constant.ALT_TAG_ICON_PROMO}
        />
      ),
    },
    {
      label: t("home.vip.title"),
      code: "Vip",
      icon: (
          <Image
              width="35px"
              height="35px"
              src={PATH + `/assets/img/wallet/wallet_card/vip_2.png`}
              alt={Constant.ALT_TAG_ICON_PROMO}
          />
      ),
    },
  ];

  const homePageSeo = [
    <>
      <Typography fontWeight={800}>{i18n.t("home.seo.firsttitle")}</Typography>
      <Typography mt={2}>{t("home.seo.firstdesc")} </Typography>
    </>,
    <>
      <Typography fontWeight={800}>{i18n.t("home.seo.secondtitle")}</Typography>
      <Typography mt={2}>{t("home.seo.seconddesc")} </Typography>
    </>,
    <>
      <Typography fontWeight={800}>{i18n.t("home.seo.thirdtitle")} </Typography>
      <Typography mt={2}>{t("home.seo.thirddesc")} </Typography>
    </>,
  ];

  const {
    data: gameCategory,
    isFetching: isGameCategoryFetching,
    isLoading: isGameCategoryLoading,
    isError: isGameCategoryError,
    isSuccess: isGameCategorySuccess,
    error: gameCategoryError,
  } = useGetGameCategoryQuery({ prefetch: true });

  const [
    getGameProvider,
    {
      data: gameProvider,
      isFetching: isGameProviderFetching,
      isLoading: isGameProviderLoading,
      isError: isGameProviderError,
      isSuccess: isGameProviderSuccess,
      error: gameProviderError,
    },
  ] = useLazyGetGameProviderQuery("", { prefetch: true });

  const {
    data: allGameProvider,
    isFetching: isAllGameProviderFetching,
    isLoading: isAllGameProviderLoading,
    isError: isAllGameProviderError,
    isSuccess: isAllGameProviderSuccess,
    error: allGameProviderError,
  } = useGetGameProviderQuery("", { prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  const {
    data: homePage,
    isFetching: isHomePageFetching,
    isLoading: isHomePageLoading,
    isError: isHomePagetError,
    isSuccess: isHomePageSuccess,
    error: homePageError,
  } = useGetHomePageQuery({ prefetch: true });

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery({ prefetch: true });


  useEffect(() => {
    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "WEB") {
        //window.open(launchGame.result.gameUrl, "_blank");
        if (launchGame?.result?.iframeSupported == true) {
          window.location.href = process.env.REACT_APP_GAME_URL_IFRAME + launchGame.result.gameUrl;
        }
        else {
          window.open(launchGame.result.gameUrl, "_blank");
        }

      }
    }
  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess]);

  const add = () => setNumber((number) => number + 1);

  useEffect(() => {
    setInterval(() => {
      add();
    }, 1000);
  }, []);

  useEffect(() => {
    getGameProvider(selectedGameCategory);
  }, [selectedGameCategory, gameCategory]);

  useEffect(() => {
    if (searchParams.get("refCode")) {
      localStorage.setItem("refCode", searchParams.get("refCode"));
      navigate("/register");
    }
  }, []);

  const popup = process.env.REACT_APP_POPUP;

  const closePopup = () => setPopupOpen(false);

  if (
    isHomePageFetching ||
    isHomePageLoading ||
    isAllGameProviderFetching ||
    isAllGameProviderLoading ||
    isGameCategoryFetching ||
    isGameCategoryLoading
  ) {
    return <Backdrop />;
  }

  if (isLaunchGameFetching || isLaunchGameLoading) {
    return <Backdrop />;
  }

  return (
    <Box pt={{ xs: 7, sm: 0, lg: 0, xl: 0 }}>
      {/* Announcement */}
      {homePage?.result?.announcements && (
        <Announcement announcementDetails={homePage?.result?.announcements} />
      )}
      {/* Banner */}
      <ImageCarousel
        banners={
          mobileView
            ? homePage?.result?.mobileBanners
            : homePage?.result?.banners
        }
        navigation={false}
        spaceBetween={0}
        alt={Constant.ALT_TAG_BANNER}
      />
      {/* Mobile view login & register (below banner) */}
      {mobileView && (
        <Box sx={{ display: "flex" }}>
          <Button
            variant="contained"
            sx={{
              fontWeight: "600",
              borderRadius: 0,
              background: `${theme.palette.button.secondary.main}`,
              width: "100%",
            }}
            disableRipple
            onClick={() =>
              isLoggedIn
                ? navigate(Constant.getPath("/profileWallet"), { state: { screen: "Deposit" } })
                : navigate(Constant.getPath("/register"))
            }
          >
            {isLoggedIn ? t("home.deposit") : t("home.signup")}
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: "600",
              width: "100%",
              borderRadius: 0,
              background: `linear-gradient(to bottom, #690608, rgb(162, 7, 2))`,
            }}
            disableRipple
            onClick={() =>
              isLoggedIn
                ? navigate(Constant.getPath("/profileWallet"), {
                    state: { screen: "Withdrawal" },
                  })
                : navigate(Constant.getPath("/login"))
            }
          >
            {isLoggedIn ? t("home.withdraw") : t("home.login")}
          </Button>
        </Box>
      )}
      {/*Mobile View Wallet Card */}
      {mobileView && isLoggedIn && (
        // <Box
        //   sx={{
        //     background: `url('${homePage?.mobileBackground[0]?.thumbnailUrl}')`,
        //   }}
        // >
        //   <Container>
        //     <Box py={2}>
        <Box py={1}>
          <WalletCard screens={walletCardScreens} />
        </Box>
        //   </Container>
        // </Box>
      )}
      {/* Mobile view Game Category & Header Bar */}
      {mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pb={2}
          >
            <Box
              p={1}
              display="flex"
              sx={{
                background: `linear-gradient(to bottom, #690608, rgb(162, 7, 2))`,
              }}
            >
              <Grid container>
                {gameCategory?.result.map((item, index) => (
                  <Grid key={index} item xs={3} p={1}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      position="relative"
                      sx={{
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      onClick={() => setSelectedGameCategory(item.code)}
                      alignItems="center"
                    >
                      <>
                        {item.code === "SLOT" ? (
                          // <SlotIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/SLOT.png"
                            }
                            alt={Constant.ALT_TAG_ICON_SLOT}
                          />
                        ) : item.code === "LIVE_CASINO" ? (
                          // <CasinoIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO +
                              "/LIVE_CASINO.png"
                            }
                            alt={Constant.ALT_TAG_ICON_CASINO}
                          />
                        ) : item.code === "SPORTBOOK" ? (
                          // <SportIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/SPORT.png"
                            }
                            alt={Constant.ALT_TAG_ICON_SPORT}
                          />
                        ) : item.code === "FISH_HUNTER" ? (
                          // <FishingIcon
                          //   width={40}
                          //   height={40}
                          //   color={
                          //     item.code === selectedGameCategory
                          //       ? theme.palette.secondary.main
                          //       : "white"
                          //   }
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/FISHING.png"
                            }
                            alt={Constant.ALT_TAG_ICON_FISHING}
                          />
                        ) : item.code === "ESPORT" ? (
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/ESPORT.png"
                            }
                            alt={Constant.ALT_TAG_ICON_ESPORT}
                          />
                        ) : item.code === "POKER" ? (
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO + "/POKER.png"
                            }
                            alt={Constant.ALT_TAG_ICON_POKER}
                          />
                        ) : null}
                        {item.code === selectedGameCategory && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: -5,
                              left: "50%",
                              transform: "translateX(-50%)",
                              borderBottom: "2px solid",
                              width: "20%",
                              borderColor: theme.palette.secondary.main,
                            }}
                          />
                        )}
                        <Typography
                          color={
                            item.code === selectedGameCategory
                              ? theme.palette.secondary.main
                              : "white"
                          }
                          textAlign="center"
                          fontSize={12}
                          fontWeight={600}
                        >
                          {item?.label}
                        </Typography>
                      </>
                    </Box>
                  </Grid>
                ))}
                {!isLoggedIn &&
                  additionalGameBarItems.map((item, index) => (
                    <Grid key={index} item xs={3} p={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        sx={{
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={() => navigate(Constant.getPath("/" + item.code?.toLowerCase()))}
                        alignItems="center"
                      >
                        {item?.label === t("home.promotion") ? (
                          // <PromotionIcon
                          //   width={40}
                          //   height={40}
                          //   color={"white"}
                          // />
                          <Image
                            width={40}
                            height={40}
                            src={
                              process.env.REACT_APP_HEADER_LOGO +
                              "/PROMOTION.png"
                            }
                            alt={Constant.ALT_TAG_ICON_PROMO}
                          />
                        ) : item?.label === t("home.vip.title") ? (
                          // <VipIcon width={40} height={40} color={"white"} />
                          <Image
                            width={40}
                            height={40}
                            src={process.env.REACT_APP_HEADER_LOGO + "/VIP.png"}
                            alt={Constant.ALT_TAG_ICON_VIP}
                          />
                        ) : null}
                        <Typography
                          color={"white"}
                          textAlign="center"
                          fontSize={12}
                          fontWeight={600}

                        >
                          {item?.label}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Box mt={1} p={1} display="flex">
              {!isGameProviderFetching &&
                !isGameProviderLoading &&
                isGameProviderSuccess && (
                  <>
                    <Grid
                      container
                      sx={{
                        border: 1,
                        borderColor: theme.palette.primary.main,
                      }}
                      py={2}
                    >
                      {gameProvider?.result?.map((provider, idx) => (
                        <Grid
                          key={idx}
                          item
                          xs={3}
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Box
                            onClick={() => {
                              let hasGameList = gameCategory?.result?.find(
                                (category) =>
                                  category.code === selectedGameCategory
                              )?.hasGameList;
                              if (hasGameList) {
                                navigate(
                                  `/game/${selectedGameCategory?.toLowerCase()}`,
                                  {
                                    state: {
                                      providerId: provider.id,
                                    },
                                  }
                                );
                              } else {
                                if (!isLoggedIn) {
                                  dispatch(
                                    openModal({
                                      title: t("dialog.proceedtologin"),
                                      button: [
                                        {
                                          label: t("dialog.login"),
                                          onSubmit: () => {
                                            dispatch(closeModal());
                                            navigate(Constant.getPath("/login"));
                                          },
                                        },
                                      ],
                                    })
                                  );
                                } else {
                                  getLaunchGame({
                                    id: provider?.id,
                                    type: provider?.gameType,
                                    category: selectedGameCategory,
                                  });
                                }
                              }
                            }}
                          >
                            <Box display="flex" flexDirection="column">
                              <Image src={provider.thumbnailUrl} alt={Constant.ALT_TAG_GENERAL_PROVIDER + provider.name}/>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
            </Box>
          </Box>
        </Box>
      )}
      {/* Description picture under banner */}
      {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[0]?.thumbnailUrl}')`,
          }}
        >
          <Image src={homePage?.result?.common?.web_promote_banner} alt={Constant.ALT_TAG_STEPS}/>
        </Box>
      )}
      {/* Progressive jackpot */}
      {!mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[1]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
              }}
              pt={5}
            >
              <Image src={homePage?.result?.common?.jackpot} alt={Constant.ALT_TAG_JACKPOT} />
              <Box
                sx={{
                  position: "absolute",
                  top: tabletView ? "86%" : "85%",
                  left: "49.2%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <FlipNumbers
                  height={70}
                  width={tabletView ? 47 : 70}
                  color="black"
                  background="transparent"
                  play
                  numberStyle={{
                    fontSize: tabletView ? 18 : 25,
                    fontWeight: "bold",
                  }}
                  nonNumberStyle={{
                    fontSize: 40,
                    color: theme.palette.secondary.main,
                  }}
                  className="flip-numbers"
                  numbers={`${number}`}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* Live Casino */}
      {!mobileView && homePage?.result?.liveCasino && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[2]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={4}>
              <Typography
                pb={3}
                textAlign="center"
                variant="h6"
                fontWeight="800"
              >
                {t("home.livecasino")}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/game/live_casino")}
                >
                  <Image src={homePage?.result?.liveCasino[0]} />
                </Box>
                <ImageCarousel
                  banners={homePage?.result?.liveCasino.slice(1)}
                  slidesPerView={2}
                  spaceBetween={0}
                  navigation={false}
                  direction="vertical"
                  autoHeight={true}
                  loop={true}
                  style={{
                    padding: 0,
                  }}
                  onClick={() => navigate("/game/live_casino")}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* Hot Games */}
      {!mobileView && homePage?.result?.featureGames && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[3]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={4}>
              <Typography
                pb={3}
                textAlign="center"
                variant="h6"
                fontWeight="800"
              >
                {t("home.hotgames")}
              </Typography>
              {/* 170x162 */}
              <CardCarousel
                imageWidth="170px"
                imageHeight="162px"
                data={{
                  result: homePage?.result?.featureGames,
                  allGameProvider: allGameProvider?.result,
                  gameCategory: gameCategory?.result,
                }}
                slidesPerView={!tabletView ? 6 : 4}
                pagination={false}
                navigation={false}
                spaceBetween={0}
              />
            </Box>
          </Container>
        </Box>
      )}
      {/* Feature */}
      {!mobileView && homePage?.result?.common?.web_wallet_step && (
        <Box
          sx={{
            background: `url('${homePage?.webBackground[4]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={4}>
              <Box
                sx={{
                  p: 1,
                  background: `url(${process.env.REACT_APP_WALLET_STEP_BG})`,
                }}
              >
                <Typography
                  pb={3}
                  textAlign="center"
                  variant="h6"
                  fontWeight="800"
                >
                  {t("home.feature.title")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_1} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.optimizedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.optimizeddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      pt={1}
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_2} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.speedytitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.speedydesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_3} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.certifiedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.certifieddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image src={process.env.REACT_APP_WALLET_STEP_CIR_4} />
                      <Typography
                        fontWeight={600}
                        fontSize={18}
                        textAlign="center"
                        pt={1}
                      >
                        {t("home.feature.safeguardedtitle")}
                      </Typography>
                      <Typography fontSize={14} textAlign="center">
                        {t("home.feature.safeguardeddesc")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* Promotion */}
      {homePage?.result?.bonuses && (
        <Box
          sx={{
            background: mobileView
              ? `url('${homePage?.mobileBackground[1]?.thumbnailUrl}')`
              : `url('${homePage?.webBackground[5]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box pt={mobileView ? 0 : 4} pb={mobileView ? 3 : 5}>
              <Typography
                mb={3}
                textAlign="center"
                variant="h6"
                fontWeight={600}
              >
                {t("home.promotion")}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={!mobileView ? 6 : 12}>
                  <ImageCarousel
                    banners={homePage?.result?.bonuses}
                    slidesPerView={1}
                    pagination={true}
                    imageStyle={{ borderRadius: 3 }}
                    onClick={() => navigate("/promotion")}
                  />
                </Grid>
                <Grid item xs={!mobileView ? 6 : 12}>
                  <Image
                    sx={{ borderRadius: 3 }}
                    src={homePage?.result?.common?.live_help}
                    onClick={() => {
                      dispatch(
                          openModal({
                            title: t("home.verificationContent"),
                            content: (
                                <>
                                  <div
                                      style={{paddingBottom: "30px"}}
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                            homePage?.result?.verification_content
                                        ),
                                      }}
                                  ></div>
                                   {isLoggedIn && (
                                                              <div style={{ display: 'flex', justifyContent: 'center',
                                                                position: "absolute", bottom: 0, width: "100%", padding: "20px",
                                                                background: theme.palette.background.paper, left: 0 }}>
                                                                <TextField
                                                                  label={t("walletcard.myaccountpage.referrallink")}
                                                                  value={shareLink?.result?.url}
                                                                  InputLabelProps={{ shrink: true }}
                                                                  disabled
                                                                  type="text"
                                                                  size="small"
                                                                  sx={{ width: "400px" }}
                                                                  InputProps={{
                                                                    endAdornment: (
                                                                      <InputAdornment position="end">
                                                                        <IconButton
                                                                          disableRipple
                                                                          sx={{
                                                                            "&.MuiButtonBase-root:hover": {
                                                                              backgroundColor: "transparent",
                                                                            },
                                                                          }}
                                                                        >
                                                                          <CopiedTooltip text={shareLink?.result?.url} />
                                                                        </IconButton>
                                                                      </InputAdornment>
                                                                    ),
                                                                  }}
                                                                />
                                                              </div>
                                                            )}
                                </>
                            ),
                            button: !isLoggedIn
                                ? [
                                  {
                                    label: t("promotion.login"),
                                    onSubmit: () => {
                                      dispatch(closeModal());
                                      if (mobileView) {
                                        navigate("/login");
                                      } else {
                                        dispatch(
                                            openModal({
                                              title: t("dialog.proceedtologin"),
                                              button: [
                                                {
                                                  label: t("dialog.login"),
                                                  onSubmit: () =>
                                                      dispatch(closeModal()),
                                                },
                                              ],
                                            })
                                        );
                                      }
                                    },
                                  },
                                  {
                                    label: t("promotion.register"),
                                    onSubmit: () => {
                                      dispatch(closeModal());
                                      navigate("/register");
                                    },
                                  },
                                ]
                                : [],
                          })
                      );
                    }}
                  />

                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
      {/* Mobile view feature banner */}
      {mobileView && (
        <Box
          sx={{
            background: `url('${homePage?.mobileBackground[2]?.thumbnailUrl}')`,
          }}
        >
          <Container>
            <Box>
              <Image src={homePage?.result?.walletSteps} />
            </Box>
          </Container>
        </Box>
      )}
      <br/>
      {/* Misc section */}
      {/*{mobileView && (*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      background: `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Container>*/}
      {/*      <Box pt={2} pb={11}>*/}
      {/*        <Typography*/}
      {/*          sx={{ cursor: "pointer" }}*/}
      {/*          onClick={() => navigate("/about")}*/}
      {/*          fontWeight={600}*/}
      {/*          py={1}*/}
      {/*        >*/}
      {/*          {t("home.misc.aboutus")}*/}
      {/*        </Typography>*/}
      {/*        <Divider />*/}
      {/*        <Typography*/}
      {/*          sx={{ cursor: "pointer" }}*/}
      {/*          onClick={() => navigate("/vip")}*/}
      {/*          fontWeight={600}*/}
      {/*          py={1}*/}
      {/*        >*/}
      {/*          {t("home.misc.vip")}*/}
      {/*        </Typography>*/}
      {/*        <Divider />*/}
      {/*        <Typography*/}
      {/*          sx={{ cursor: "pointer" }}*/}
      {/*          onClick={() => null}*/}
      {/*          fontWeight={600}*/}
      {/*          py={1}*/}
      {/*        >*/}
      {/*          {t("home.misc.affiliate")}*/}
      {/*        </Typography>*/}
      {/*        <Divider />*/}
      {/*        <Typography*/}
      {/*          sx={{ cursor: "pointer" }}*/}
      {/*          onClick={() => null}*/}
      {/*          fontWeight={600}*/}
      {/*          py={1}*/}
      {/*        >*/}
      {/*          {t("home.misc.livechat")}*/}
      {/*        </Typography>*/}
      {/*        <Divider />*/}
      {/*        <Typography*/}
      {/*          sx={{ cursor: "pointer" }}*/}
      {/*          onClick={() => navigate("/terms")}*/}
      {/*          fontWeight={600}*/}
      {/*          py={1}*/}
      {/*        >*/}
      {/*          {t("home.misc.terms")}*/}
      {/*        </Typography>*/}
      {/*        <Divider />*/}
      {/*      </Box>*/}
      {/*    </Container>*/}
      {/*  </Box>*/}
      {/*)}*/}
      {/* SEO text */}
      {/* <Box
        sx={{
          background: mobileView
            ? `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`
            : `url('${homePage?.webBackground[6]?.thumbnailUrl}')`,
        }}
      >
        <Container>
          <Box pt={4}>
            <TextCarousel
              html={homePageSeo}
              slidesPerView={1}
              pagination={true}
            />
          </Box>
        </Container>
      </Box> */}
      {/* Accordion of terms */}
      {/* <Box
        sx={{
          background: mobileView
            ? `url('${homePage?.mobileBackground[3]?.thumbnailUrl}')`
            : `url('${homePage?.webBackground[6]?.thumbnailUrl}')`,
        }}
      >
        <Container>
          <Box pt={{ xs: 4, sm: 4, md: 4 }} pb={{ xs: 11, sm: 5, md: 5 }}>
            <AccordionComponent title={t("home.t&c.firsttitle")}>
              {t("home.t&c.firstdesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.secondtitle")}>
              {t("home.t&c.seconddesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.thirdtitle")}>
              {t("home.t&c.thirddesc")}
            </AccordionComponent>
            <AccordionComponent title={t("home.t&c.fourthtitle")}>
              {t("home.t&c.fourthdesc")}
            </AccordionComponent>
          </Box>
        </Container>
      </Box> */}
      {/* Default pop up */}
      {/* <Modal open={popupOpen} onClose={closePopup}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: mobileView ? 350 : null,
            transform: "translate(-50%, -50%)",
            outline: "none",
          }}
          textAlign="right"
        >
          <Image src={popup} sx={{ borderRadius: "5%" }} />
        </Box>
      </Modal> */}
    </Box>
  );
};

export default Home;
