import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import i18n from "i18next";

import {
  useGetGameCategoryQuery,
  useGetGameProviderQuery,
} from "../features/api/gameApiSlice";
import Image from "./common/Image";
import { useTranslation } from "react-i18next";

const PATH = process.env.PUBLIC_URL;

const gameProviderIcon = [
  PATH + "/assets/img/footer_game_provider/pragmatic.png",
  PATH + "/assets/img/footer_game_provider/sexybaccarat.png",
  PATH + "/assets/img/footer_game_provider/playngo.png",
  PATH + "/assets/img/footer_game_provider/dreamgaming.png",
  PATH + "/assets/img/footer_game_provider/spadegaming.png",
  PATH + "/assets/img/footer_game_provider/sagaming.png",
  PATH + "/assets/img/footer_game_provider/ygr.png",
  PATH + "/assets/img/footer_game_provider/wmcasino.png",
  PATH + "/assets/img/footer_game_provider/evolution.png",
  PATH + "/assets/img/footer_game_provider/nextspin.png",
  PATH + "/assets/img/footer_game_provider/redtiger.png",
  PATH + "/assets/img/footer_game_provider/md368.png",
  PATH + "/assets/img/footer_game_provider/ezugi.png",
  PATH + "/assets/img/footer_game_provider/jili.png",
  PATH + "/assets/img/footer_game_provider/netent.png",
  PATH + "/assets/img/footer_game_provider/ibcbet.png",
  PATH + "/assets/img/footer_game_provider/m8bet.png",
  PATH + "/assets/img/footer_game_provider/mega888.png",
  PATH + "/assets/img/footer_game_provider/pussy888.png",
  PATH + "/assets/img/footer_game_provider/918kiss.png",
];

const licenseIcon = [
  process.env.REACT_APP_FOOTER + "/license1.png",
  process.env.REACT_APP_FOOTER + "/license2.png",
  process.env.REACT_APP_FOOTER + "/license3.png",
  process.env.REACT_APP_FOOTER + "/license4.png",
  process.env.REACT_APP_FOOTER + "/license5.png",
];

const socialMedia = [
  process.env.REACT_APP_FOOTER + "/instagram.png",
  process.env.REACT_APP_FOOTER + "/facebook.png",
  process.env.REACT_APP_FOOTER + "/youtube.png",
];

const customerService = [
  process.env.REACT_APP_FOOTER + "/phone.jpg",
  process.env.REACT_APP_FOOTER + "/whatsapp.jpg",
  process.env.REACT_APP_FOOTER + "/wechat.jpg",
  process.env.REACT_APP_FOOTER + "/email.jpg",
];

const payment = [
  process.env.REACT_APP_FOOTER + "/payment1.png",
  process.env.REACT_APP_FOOTER + "/payment2.png",
];

const bank = [
  process.env.REACT_APP_FOOTER + "/bank1.png",
  process.env.REACT_APP_FOOTER + "/bank2.png",
  process.env.REACT_APP_FOOTER + "/bank3.png",
  process.env.REACT_APP_FOOTER + "/bank4.png",
];

const Footer = ({ background, contactDetails }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));

  const headerItems = [
    { label: i18n.t("footer.home"), code: "home" },
    { label: i18n.t("footer.promotion"), code: "promotion" },
    { label: i18n.t("footer.vip"), code: "vip" },
    // { label: "Affiliate", icon: <Diversity3 display="block" pb={0} /> },
  ];
  const links = [
    i18n.t("footer.home"),
    i18n.t("footer.aboutus"),
    i18n.t("footer.game"),
    i18n.t("footer.bettingrules"),
    i18n.t("footer.t&c"),
    i18n.t("footer.promotion"),
    i18n.t("footer.faq"),
  ];

  const links2 = [
    i18n.t("footer.howtojoin"),
    i18n.t("footer.howtodeposit"),
    i18n.t("footer.howtowithdraw"),
  ];

  const links3 = [
    i18n.t("footer.responsiblegaming"),
    i18n.t("footer.kycverification"),
    i18n.t("footer.antiscam"),
  ];

  const {
    data: gameCategory,
    isLoading: isGameCategoryLoading,
    isSuccess: isGameCategorySuccess,
  } = useGetGameCategoryQuery();

  const {
    data: gameProvider,
    isLoading: isGameProviderLoading,
    isSuccess: isGameProviderSuccess,
  } = useGetGameProviderQuery(gameCategory?.result[0]?.code);

  return (
    <Box
      sx={{
        background: `url('${background}')`,
      }}
    >
      <Grid
        container
        // columns={4}
        direction={mobileView ? "column" : "row"}
        wrap="nowrap"
        p={0}
        px={{ sm: "100px", md: "300px" }}
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        {gameCategory?.result.map((item, index) => {
          return (
            <Grid item xs p={2} display="flex" justifyContent="center">
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  textTransform: "UPPERCASE",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={() => navigate("/game/" + item.code?.toLowerCase())}
              >
                {item.label}
              </Typography>
            </Grid>
          );
        })}
        {headerItems.map((item, index) => {
          if (index !== 0) {
            return (
              <Grid item xs p={2} display="flex" justifyContent="center">
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                    textTransform: "UPPERCASE",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/" + item.code?.toLowerCase())}
                >
                  {item.label}
                </Typography>
              </Grid>
            );
          }
        })}
      </Grid>
      <Grid
        container
        spacing={2}
        direction={mobileView ? "column" : "row"}
        // px={{ sm: "100px", md: "300px" }}
        mt="10px"
        sx={{
          display: "flex",
          justifyContent: !tabletView && "center",
        }}
        pb="10px"
        px={10}
      >
        {/* links */}
        <Grid item xs={12} sm={6} md={2}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.support")}
              </Typography>
              {links.map((item, index) => (
                <Box
                  disableRipple
                  sx={{
                    color: "#727272 !important",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.secondary.main,
                    },
                    fontSize: 12,
                    textTransform: "UPPERCASE",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item === t("footer.home")) {
                      navigate("/");
                    } else if (item === t("footer.game")) {
                      navigate(
                        `/game/${gameCategory?.result[0].code?.toLowerCase()}`,
                        {
                          state: {
                            providerId: gameProvider?.result[0]?.id,
                            bannerUrl: gameCategory?.result[0].bannerUrl,
                          },
                        }
                      );
                    } else if (item === t("footer.promotion")) {
                      navigate("/promotion");
                    } else if (item === t("footer.aboutus")) {
                      navigate("/about");
                    } else if (item === t("footer.bettingrules")) {
                      navigate("/rules");
                    } else if (item === t("footer.t&c")) {
                      navigate("/terms");
                    } else if (item === t("footer.faq")) {
                      navigate("/faq");
                    }
                    window.scroll(0, 0);
                  }}
                  underline="none"
                >
                  {item}
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {/* links */}
        {/* start */}
        <Grid item xs={12} sm={6} md={2}>
          <Grid container spacing={2}>
            <Grid item height={120}>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.tutorial")}
              </Typography>
              {links2.map((item, index) => (
                <Box
                  disableRipple
                  sx={{
                    color: "#727272 !important",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.secondary.main,
                    },
                    fontSize: 12,
                    textTransform: "UPPERCASE",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item === t("footer.howtojoin")) {
                      navigate("/howToJoin");
                    } else if (item === t("footer.howtodeposit")) {
                      navigate("/howToDeposit");
                    } else if (item === t("footer.howtowithdraw")) {
                      navigate("/howToWithdraw");
                    }
                    window.scroll(0, 0);
                  }}
                  underline="none"
                >
                  {item}
                </Box>
              ))}
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.customerservice")}
              </Typography>
              <Grid container spacing={2}>
                {customerService.map((icon) => (
                  <Grid
                    item
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://www.google.com", "_blank")
                    }
                  >
                    <Image width="30px" height="30px" src={icon} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Grid container spacing={2}>
            <Grid item height={120}>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.responsiblegaming")}
              </Typography>
              {links3.map((item, index) => (
                <Box
                  disableRipple
                  sx={{
                    color: "#727272 !important",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.secondary.main,
                    },
                    fontSize: 12,
                    textTransform: "UPPERCASE",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (item === t("footer.responsiblegaming")) {
                      navigate("/responsibleGaming");
                    } else if (item === t("footer.kycverification")) {
                      navigate("/kycVerification");
                    } else if (item === t("footer.antiscam")) {
                      navigate("/antiScam");
                    }
                    window.scroll(0, 0);
                  }}
                  underline="none"
                >
                  {item}
                </Box>
              ))}
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.followus")}
              </Typography>
              <Grid container spacing={2}>
                {socialMedia.map((icon) => (
                  <Grid
                    item
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://www.google.com", "_blank")
                    }
                  >
                    <Image width="30px" height="30px" src={icon} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* end */}
        {/* games */}
        <Grid item xs={12} sm={6} md={2}>
          <Grid container spacing={2}>
            <Grid item height={120}>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.licenses")}
              </Typography>
              <Grid container columns={2}>
                <Grid container justifyContent="flex-start" py={2}>
                  {licenseIcon.map((icon) => (
                    <Grid item xs={4} display="flex">
                      <Image src={icon} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.dafuwin")}
              </Typography>
              <Grid container columns={2}>
                <Box py={2}>
                  <Image width="200px" src={process.env.REACT_APP_LOGO} />
                </Box>
              </Grid>
            </Grid>{" "}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Grid container spacing={2}>
            {/*<Grid item height={120}>*/}
            {/*  <Typography*/}
            {/*    variant="h6"*/}
            {/*    sx={{*/}
            {/*      color: "white",*/}
            {/*      textTransform: "UPPERCASE",*/}
            {/*      fontSize: "1em",*/}
            {/*      fontWeight: "600",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {t("footer.paymentmethod")}*/}
            {/*  </Typography>*/}
            {/*  <Grid container columns={2}>*/}
            {/*    <Grid container justifyContent="flex-start" py={2}>*/}
            {/*      {payment.map((icon) => (*/}
            {/*        <Grid item xs={4} display="flex">*/}
            {/*          <Image src={icon} />*/}
            {/*        </Grid>*/}
            {/*      ))}*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Grid item>
              <Typography
                variant="h6"
                sx={{
                  color: "white",
                  textTransform: "UPPERCASE",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                {t("footer.supportedbank")}
              </Typography>
              <Grid container columns={2}>
                <Grid container justifyContent="flex-start" py={2}>
                  {bank.map((icon) => (
                    <Grid item xs={4} display="flex">
                      <Image src={icon} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

Footer.propTypes = {
  background: PropTypes.string,
  contactDetails: PropTypes.object,
};

Footer.defaultProps = {
  background: "",
  contactDetails: {},
};

export default Footer;
