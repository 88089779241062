import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  Pagination,
  Stack,
  darken,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DateRangePicker from "../form/DateRangePicker";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

// not going to refactor this part for now
const PromotionRecord = ({ useGetPromotionRecordMutation }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const type = [
    { label: t("walletcard.promotionrecordpage.all"), value: "All" },
    { label: t("walletcard.promotionrecordpage.ongoing"), value: "Ongoing" },
    {
      label: t("walletcard.promotionrecordpage.completed"),
      value: "Completed",
    },
    { label: t("walletcard.promotionrecordpage.rejected"), value: "Rejected" },
  ];

  const [statusType, setStatusType] = useState(type[0].value);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [offset, setOffset] = useState(0);

  const [
    promotionRecord,
    result,
    // { data, isLoading, isError, isSuccess, error },
  ] = useGetPromotionRecordMutation();

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleOnChangeType = (event) => {
    setStatusType(event.target.value);
  };

  const handlePageChange = (e, p) => {
    setOffset(p - 1);
  };

  useEffect(() => {
    if (!(endDate < startDate)) {
      let data = {
        criteria: {
          dateFrom: format(startDate, "dd-MM-yyyy"),
          dateTo: format(endDate, "dd-MM-yyyy"),
          status: statusType.toUpperCase(),
        },
        offset: offset,
      };
      promotionRecord(data).unwrap();
    }
  }, [startDate, endDate, statusType, offset]);

  return (
    <Container>
      <Typography fontWeight="bold">
        {t("walletcard.promotionrecord")}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={mobileView ? "column" : "row"}
        mt="15px"
      >
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          handleStartDate={handleStartDate}
          handleEndDate={handleEndDate}
          size="small"
        />
        <Box mt={mobileView ? "10px" : null}>
          <FormControl>
            <InputLabel id="type">
              {t("walletcard.promotionrecordpage.type")}
            </InputLabel>
            <Select
              value={statusType}
              onChange={handleOnChangeType}
              label="Type"
              labelId="type"
              size="small"
            >
              {type.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ height: 400, width: "100%", mt: "10px" }}>
        <DataGrid
          sx={{
            "& .MuiDialogContent-root": {
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "12px",
              },
              "::-webkit-scrollbar-track": {
                background: theme.palette.text.disabled,
              },
              "::-webkit-scrollbar-thumb": {
                background: theme.palette.background.paper,
              },
            },
          }}
          rows={
            result?.data?.result?.content.map((content, idx) => {
              return {
                ...content,
                createdDate: format(
                  new Date(content?.createdDate),
                  "dd-MM-yyyy"
                ),
                rollover: `${content?.currentRolloverAmount}/${content?.withdrawalMinRolloverAmount}`,
                turnover: `${content?.currentTurnoverAmount}/${content?.withdrawalMinTurnoverAmount}`,
                id: idx + 1,
              };
            }) || []
          }
          columns={[
            {
              field: "createdDate",
              headerName: t("walletcard.promotionrecordpage.date"),
              width: 120,
              editable: false,
            },
            {
              field: "title",
              headerName: t("walletcard.promotionrecordpage.promoname"),
              width: 250,
              editable: false,
            },
            {
              field: "rollover",
              headerName: t("walletcard.promotionrecordpage.rollovertarget"),
              width: 150,
              editable: false,
            },
            {
              field: "turnover",
              headerName: t("walletcard.promotionrecordpage.turnovertarget"),
              width: 150,
              editable: false,
            },
            {
              field: "status",
              headerName: t("walletcard.promotionrecordpage.status"),
              width: 150,
              editable: false,
            },
          ]}
          hideFooter={true}
          rowsPerPageOptions={[5]}
          // checkboxSelection
          // disableSelectionOnClick
          // isCellEditable={false}
          onPageChange={(newPage) => setOffset(newPage)}
        />
      </Box>
      <Stack mt={2} spacing={2} size="small" alignItems="center">
        <Pagination
          // disabled={selectedAnnouncement.selected ? true : false}
          // onChange={handlePageChange}
          onChange={handlePageChange}
          count={result?.data?.result?.totalPages}
          shape="rounded"
        />
      </Stack>
    </Container>
  );
};

export default PromotionRecord;
