import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import React from "react";
import PropTypes from "prop-types";
import Image from "./Image";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectCoverflow,
} from "swiper";
import { useTheme } from "@mui/material";

const ImageCarousel = ({
  banners,
  slidesPerView,
  spaceBetween,
  effect,
  pagination,
  style,
  onClick,
  direction,
  navigation,
  loopedSlides,
  loopAdditionalSlides,
  loop,
  imageStyle,
  autoHeight,
}) => {
  const theme = useTheme();

  return (
    <Swiper
      effect={effect}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      style={{
        ...style,
        "--swiper-pagination-color": theme.palette.primary.main,
        "--swiper-pagination-bullet-size": "10px",
        "--swiper-navigation-color": theme.palette.primary.main,
        "--swiper-navigation-size": "30px",
      }}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      navigation={navigation}
      pagination={pagination ? { clickable: true } : pagination}
      mousewheel={false}
      keyboard={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      loop={loop}
      loopedSlides={loopedSlides}
      loopFillGroupWithBlank={false}
      loopAdditionalSlides={loopAdditionalSlides}
      direction={direction}
      modules={[
        Autoplay,
        Navigation,
        Pagination,
        Mousewheel,
        Keyboard,
        EffectCoverflow,
      ]}
      autoHeight={autoHeight}
    >
      {banners.map((item, index) => {
        return (
          <SwiperSlide
            key={index}
            onClick={onClick}
            style={{ borderRadius: "100px" }}
          >
            <Image
              src={item?.thumbnailUrl || item}
              key={index}
              sx={{
                ...imageStyle,
                cursor: "pointer",
              }}
              width="100%"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

ImageCarousel.propTypes = {
  banners: PropTypes.array,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  effect: PropTypes.string,
  pagination: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
  direction: PropTypes.string,
  navigation: PropTypes.bool,
  loop: PropTypes.bool,
  loopedSlides: PropTypes.number,
  loopAdditionalSlides: PropTypes.number,
  autoHeight: PropTypes.bool,
};

ImageCarousel.defaultProps = {
  banners: [],
  slidesPerView: 1,
  spaceBetween: 20,
  effect: null,
  pagination: true,
  style: {
    "--swiper-navigation-color": "#FFF",
    "--swiper-navigation-size": "40px",
    "--swiper-pagination-color": "#FFF",
  },
  onClick: () => null,
  type: null,
  direction: "horizontal",
  navigation: true,
  loop: false,
  loopedSlides: null,
  loopAdditionalSlides: null,
  autoHeight: false,
};

export default ImageCarousel;
