import React, { useState, useEffect } from "react";
import { Box, Menu, Typography, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Image from "./Image";
import { useTranslation } from "react-i18next";
import { darken } from "@material-ui/core";

const LanguageDropdown = ({ languages }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const handleLanguageChange = () => {
      localStorage.setItem("language", i18n.language);
      window.location.reload();
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const handleOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderItems = () => (
    <Box display="flex" flexDirection="column" px={2}>
      <Typography>{t("translation.country")}</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
      >
        <Image width="20px" height="20px" src={process.env.REACT_APP_SG_ICON} />
        <Box display="flex" pl={1}>
          {languages?.map((language) => (
            <Typography
              px={1}
              sx={{
                cursor: "pointer",
                color:
                  i18n.language.toUpperCase() === language.code
                    ? "#1E90FF"
                    : "black",
              }}
              onClick={() => {
                i18n.changeLanguage(language.code?.toLowerCase());
                handleClose();
              }}
            >
              {language?.label}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );

  const renderMenu = () => (
    <Menu
      sx={{ mt: "40px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {renderItems()}
    </Menu>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: darken(theme.palette.background.paper, 0.2),
        borderRadius: 4,
        p: 1,
        ml: 1,
        cursor: "pointer",
      }}
      onClick={handleOpen}
    >
      <Image width="20px" height="20px" src={process.env.REACT_APP_SG_ICON} />
      <KeyboardArrowDown />
      {renderMenu()}
    </Box>
  );
};

export default LanguageDropdown;
