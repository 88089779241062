import { Box, Typography, Container, useTheme } from "@mui/material";
import AccordionComponent from "../components/common/Accordion";
import { useTranslation } from "react-i18next";

const Rules = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Container>
        <Box my={4}>
          <Typography
            variant="h6"
            component="h6"
            fontWeight={800}
            color={theme.palette.primary.main}
          >
            {t("bettingrules.title")}
          </Typography>
          <Typography variant="body2">
            <ol>
              <li style={{ margin: "16px" }}>{t("bettingrules.1")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.2")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.3")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.4")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.5")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.6")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.7")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.8")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.9")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.10")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.11")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.12")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.13")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.14")}</li>
              <li style={{ margin: "16px" }}>{t("bettingrules.15")}</li>
            </ol>
          </Typography>
          {/* Rules accordion */}
          <AccordionComponent title={t("bettingrules.16.title")}>
            {t("bettingrules.16.desc")}
          </AccordionComponent>
          <AccordionComponent title={t("bettingrules.17.title")}>
            {t("bettingrules.17.desc")}
          </AccordionComponent>
          <AccordionComponent title={t("bettingrules.18.title")}>
            {t("bettingrules.18.desc")}
          </AccordionComponent>
        </Box>
      </Container>
    </>
  );
};

export default Rules;
