import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectCoverflow,
} from "swiper";
import { useTheme, useMediaQuery } from "@mui/material";
import GameCard from "../Game/GameCard";

const CardCarousel = ({
  data,
  slidesPerView,
  spaceBetween,
  effect,
  pagination,
  style,
  imageHeight,
  imageWidth,
  navigation,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Swiper
        effect={effect}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        style={{
          "--swiper-pagination-color": theme.palette.primary.main,
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-navigation-color": theme.palette.primary.main,
        }}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={navigation}
        pagination={pagination ? { clickable: true } : pagination}
        mousewheel={false}
        keyboard={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[
          Autoplay,
          Navigation,
          Pagination,
          Mousewheel,
          Keyboard,
          EffectCoverflow,
        ]}
      >
        {data?.result?.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "0px",
                // padding: "0px",
              }}
            >
              <GameCard
                imageUrl={item?.thumbnailUrl}
                imageHeight={imageHeight}
                imageWidth={imageWidth}
                isHotGame={true}
                onClick={() => {
                  navigate("/game/" + item.categoryCode?.toLowerCase(), {
                    state: {
                      providerId: item.providerId,
                    },
                  });
                }}
                title={item?.name}
                subtitle={
                  data?.allGameProvider?.find(
                    (provider) => provider?.id === item?.providerId
                  )?.name
                }
                description={{}}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

CardCarousel.propTypes = {
  data: PropTypes.array,
  slidesPerView: PropTypes.number,
  spaceBetween: PropTypes.number,
  effect: PropTypes.string,
  pagination: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
  navigation: PropTypes.bool,
};

CardCarousel.defaultProps = {
  data: [],
  slidesPerView: 1,
  spaceBetween: 20,
  effect: null,
  pagination: true,
  style: {
    "--swiper-navigation-color": "#FFF",
    "--swiper-navigation-size": "40px",
    "--swiper-pagination-color": "#FFF",
  },
  onClick: () => null,
  type: null,
  navigation: true,
};

export default CardCarousel;
