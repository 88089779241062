import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../features/slices/modalSlice";
import { selectModalIsOpen } from "../../features/slices/modalSlice";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";

const ModalDialog = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isOpen, modalProps } = useSelector((state) => state.modal);
  const [scroll, setScroll] = useState("paper");

  const handleClose = () => {
    dispatch(closeModal());
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, []);

  return (
    <div>
      <Dialog
        open={isOpen}
        maxWidth="md"
        onClose={handleClose}
        scroll={scroll}
        sx={{
          "& .MuiPaper-root": {
            bgcolor: theme.palette.background.paper,
            width: "100%",
            margin:0,
            maxHeight: "100%"
          },
          "& .MuiDialogContent-root": {
            overflowY: "auto",
            "::-webkit-scrollbar": {
              width: "12px",
            },
            "::-webkit-scrollbar-track": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.text.disabled
                  : theme.palette.background.paper,
            },
            "::-webkit-scrollbar-thumb": {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.background.paper
                  : theme.palette.text.disabled,
            },
          },
        }}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {modalProps?.title}
            <IconButton sx={{ ml: 2 }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={"black"}
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {modalProps?.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {modalProps?.button?.map((button, idx) => (
            <Button
              key={idx}
              variant="contained"
              type="submit"
              onClick={button?.onSubmit}
              disabled={button?.disabled}
              sx={{
                fontWeight: 600,
              }}
            >
              {button?.label}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalDialog.propTypes = {};

ModalDialog.defaultProps = {};

export default ModalDialog;
